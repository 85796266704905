.fallback-view {
  text-align: center;
  padding: 70px 20px 80px;

  .section & {
    padding: 30px 20px 40px;
  }

  @include mq(480) {
    margin-top: 55px;
  }

  &__icon {
    margin-bottom: 20px;
  }

  &__title {
    width: 80%;
    margin: auto;
    font-size: 15px;
    font-weight: 500;
    color: #302019;
    font-family: $helvetica;
  }

  &__text {
    margin: 15px 0;
    font-size: 14px;
    color: #98918a;
    font-family: $helvetica;
  }

  &__btn {
    font-size: 14px;
    text-decoration: none;
    color: #3d5af3;
    font-family: $helvetica;
    background-color: transparent;
  }
}
